import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const Header = () => {
  const username = localStorage.getItem('username');
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('username');
    navigate('/');
  };

  const pageTitle = () => {
    switch (location.pathname) {
      case '/news_dash':
        return 'Dashboard';
      case '/add_news':
        return 'Ingest News';
      case '/archived_news':
        return 'Archive';
      default:
        return 'Home';
    }
  };

  return (
      <header className="w-full p-4 flex justify-between items-center">
        <div className="ml-14">
          <h1 className="text-4xl font-roboto font-medium text-[#B96663] sm:text-2xl md:text-4xl">
            {pageTitle()}
          </h1>
        </div>

        <div className="ml-auto flex flex-row items-center space-x-4">
          <div className="text-[#B96663] text-md font-bold">
          {`Welcome back, ${username}!`}
          </div>
          <div>
            {username && (
                <button
                    onClick={handleLogout}
                    className="bg-[#B96663] text-md text-white py-1 px-3 rounded hover:scale-105 duration-300"
                >
                  Logout
                </button>
            )}
          </div>
        </div>
      </header>
  );
};
export default Header;
