import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseUrl from "./shared/baseUrl";

const AddNews = () => {
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = useCallback( async () => {
        setIsLoading(true);

        try {
            const response = await axios.get(`${baseUrl}/api/add_news/`);
            if (response.status === 200 || response.status === 202) {
                toast.success('Request to update news successfully received. Refresh the dashboard in some time...');
            }
        } catch (error) {
            console.error('Error adding news:', error);
            toast.error(`Error adding news: ${error}`);
        } finally {
            setIsLoading(false);
        }
    }, []);

    return (
        <div>
            <button className="bg-gray-950 hover:bg-[#B96663] text-stone-50 font-roboto font-medium py-2 px-4 rounded-full mr-16 focus:outline-none focus:shadow-outline" type='button' onClick={fetchData} disabled={isLoading}>{isLoading ? 'Loading...' : 'Fetch Data'}</button>
            <ToastContainer />
        </div>
    );
};

export default AddNews;
