import React, { useState } from "react";
import NewsTable from "../components/NewsDashboard/NewsTable";
import LeftMenu from "../components/Layouts/LeftMenu";
import Header from "../components/Header";
import useAuth from "../useAuth";

const NewsDashboardPage = ({ fetchData }) => {
  useAuth();

  const [filterCondition, setFilterCondition] = useState(() => (article) => !article.archived && !article.deleted);

  return (
    <>
      <Header />
      <LeftMenu />

      <div
        className="flex justify-center items-center"
        style={{ padding: "0 20px" }}
      >
        <NewsTable filterCondition={filterCondition} />
      </div>
    </>
  );
};

export default NewsDashboardPage;
