import React from 'react'
import AddNews from '../components/AddNews';
import LeftMenu from '../components/Layouts/LeftMenu';
import Header from '../components/Header';
import useAuth from '../useAuth';


const FetchNewsPage = () => {
  useAuth();
  
  return (
    <>
    <Header />
    <LeftMenu />
    <div className="flex justify-center items-center" style={{ padding: '0 20px' }}> <AddNews />
    </div>
  </>
  )
}

export default FetchNewsPage;