import React from "react";
import NewsTable from "../components/NewsDashboard/NewsTable";
import LeftMenu from "../components/Layouts/LeftMenu";
import Header from "../components/Header";
import useAuth from "../useAuth";

const ArchivedNewsPage = () => {
  useAuth();

  const filterArchived = (article) => article.archived && !article.deleted;

  return (
    <>
      <Header />
      <LeftMenu />
      <div className="flex justify-center items-center" style={{ padding: "0 20px" }}>
        <NewsTable filterCondition={filterArchived} showArchiveButton={false} />
      </div>
    </>
  );
};

export default ArchivedNewsPage;
